import { yupResolver } from '@hookform/resolvers/yup';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import HttpsRoundedIcon from '@mui/icons-material/HttpsRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Grid, InputAdornment, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/system';
import { GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Input from '../../components/Input';
import { USER_ROL } from '../../config/user-rol';
import { useAlertMessage } from '../../context/notifications';
import { isLoggedIn, LoginGoogleService, LoginService } from '../../services/login';
import Fondo from './Components/assets/images/Fondo.webp';

const LoginSchema = yup.object({
  correo: yup
    .string()
    .required('* Campo requerido'),
  contrasena: yup
    .string()
    .required('* Campo requerido'),
})

const styles = {
  root: {
    backgroundImage: `url(${Fondo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // display: 'flex',
    height: '100vh',
    position: 'relative',
    minHeight: '100vh'

  },
  overOpacity: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: '#999999',
    opacity: 0.5,
  },
  content: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }

};

const Login = () => {
  const isAuthenticated = isLoggedIn()
  if (isAuthenticated) return <Navigate to={`/${USER_ROL}`} />

  const navigate = useNavigate()
  const { showError, showSuccess } = useAlertMessage()

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(LoginSchema),
    defaultValues: { correo: '', contrasena: '', rol: USER_ROL }
  })

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = (data) => {
    setIsLoading(true)
    LoginService(data).then(response => {

      localStorage.setItem('token', JSON.stringify(response.token))
      localStorage.setItem('user', JSON.stringify(jwtDecode(response.token)))

      navigate(`/${USER_ROL}`)
      showSuccess('Inicio de sesión correcto')

    }).catch(err => {
      showError(err.response.data.mensaje)
    }).finally(() => {
      setIsLoading(false)
    })

  }
  // codigo de Google Auth
  const onSuccess = (credentialResponse) => {
    setIsLoading(true)
    const decodedToken = jwtDecode(credentialResponse.credential, { complete: true });
    localStorage.setItem('picture', decodedToken.picture)
    LoginGoogleService(credentialResponse.credential, decodedToken).then(response => {

      localStorage.setItem('token', JSON.stringify(response.token))
      localStorage.setItem('user', JSON.stringify(jwtDecode(response.token)))

      navigate(`/${USER_ROL}`)
      showSuccess('Inicio de sesión correcto')

    }).catch(err => {
      console.log(err)
      showError(err.response.data.mensaje)
    }).finally(() => {
      setIsLoading(false)
    })
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div >
        <div style={styles.root}></div>
        <div style={styles.overOpacity}></div>
        {/* <img src={Fondo}></img>   */}
        <Box style={styles.content}  >
          <Avatar alt="Remy Sharp" sx={{
            m: 2, width: 150, height: 150, bgcolor: '#217E93',
            p: 2,
            position: 'relative',
            // top:10,
            zIndex: 'tooltip',
          }}>
            <AccountCircleRoundedIcon sx={{ width: 150, height: 150 }} />
          </Avatar>
          <Box sx={{
            display: 'flex', flexWrap: 'wrap',
            position: 'relative',
            bottom: 75,
          }}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                  width: { sm: 400, md: 400, xs: 360 },
                  height: 480,

                },
              }}
            >
              <Paper elevation={3} sx={{ borderRadius: 2, background: 'rgba(225,225,225,0.6)' }}>
                <Container component="main" maxWidth="xs"   >
                  <Box
                    sx={{ marginTop: 6, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  >
                    <Box sx={{ mt: 2, mx: 4 }}>
                      <Box sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 2, gap: 2, display: 'flex' }} >
                        <Box sx={{ textAlign: 'center' }}>
                          <h2 style={{ color: 'black', fontSize: 14, fontWeight: 400 }}>
                            Ingresa al sistema mediante tu cuenta de correo UNIQ o tu correo asignado
                          </h2>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                          <GoogleLogin
                            size='large'
                            onSuccess={onSuccess}
                            onError={() => {
                              console.log('Login Failed');
                            }} />
                        </Box>
                      </Box>
                      <Divider orientation="horizontal" flexItem sx={{ color: 'black', marginBottom: '20px' }}>
                        o continúa con tu cuenta
                      </Divider>

                      <Grid
                        container
                        spacing={3}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                      >
                        <Grid item xs={12}>
                          <Input
                            control={control}
                            id='correo'
                            label='Correo'
                            placeholder='Ingrese su correo'
                            type='text'
                            inputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <PersonRoundedIcon sx={{ color: 'primary.main' }} />
                                </InputAdornment>
                              )
                            }}
                          />
                          <Typography
                            variant='subtitle2'
                            align='left'
                            color='primary'
                          >
                            {errors.email?.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            control={control}
                            id='contrasena'
                            label='Contraseña'
                            placeholder='Ingrese su contraseña'
                            type='password'
                            inputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <HttpsRoundedIcon sx={{ color: 'primary.main' }} />
                                </InputAdornment>
                              )
                            }}
                          />
                          <Typography
                            variant='subtitle2'
                            align='left'
                            color='primary'
                          >
                            {errors.contrasena?.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <LoadingButton
                            loading={isLoading}
                            type='submit'
                            variant='contained'
                            fullWidth
                            sx={{
                              '&.MuiButton-contained': {
                                backgroundColor: isLoading ? 'primary.main' : undefined
                              },
                            }}
                            endIcon={<SendIcon />}
                          >
                            Ingresar
                          </LoadingButton>
                        </Grid>
                      </Grid>
                      {/* <Grid container>
                      <Grid item xs sx={{ zIndex: 'tooltip', mb: 5, textAlign: 'center', }}>
                        <Link href="#" variant="body2" >
                          ¿Olvidaste tu contraseña?
                        </Link>
                      </Grid>
                    </Grid> */}

                    </Box>
                  </Box>

                </Container>
              </Paper>
            </Box>
          </Box>
        </Box>
      </div>
    </form>
  );
}
export default Login
