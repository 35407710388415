import axios from 'axios'
import { useUsuario } from '../hooks/useUsuario'

export const AsignarAlumnosTutor = async (body) => {
    const { full_path: fullPath } = useUsuario()
    const { data } = await axios.post(`${fullPath}/TutorAlumno/asignar?CodTutor=${body.codTutor}&Observacion=${body.observacion}`, body.codAlumnos)
    return data
}

export const GetTutoresDeAlumno = async ({queryKey}) => {
    const { full_path: fullPath } = useUsuario()
  const [_key, codAlumno] = queryKey
    const { data } = await axios.post(`${fullPath}/TutorAlumno?Codigo=${codAlumno}`)
    return data.tutoAlumno
}
