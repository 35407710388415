import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Input from '../../../../../components/Input'
import ContentMainBox from '../../../../../components/MainBox'
import { useAlertMessage } from '../../../../../context/notifications'
import { useAuthContext } from '../../../../../context/userContext'
import { useCreateTicket } from '../../../../../hooks/useTicket'

const SemestreSchema = yup.object({
    codDocente: yup
        .string()
        .required('Campo requerido'),
    tema: yup
        .string()
        .required('Campo requerido'),
    fechaTicket: yup
        .date('Ingresar una fecha válida')
        .required('Campo requerido'),
    hora: yup
        .string()
        .required('Campo requerido'),
    comentario: yup
        .string()
        .required('Campo requerido'),
})

export const Ticket = ({ tutores }) => {
    const { user } = useAuthContext()
    const { mutate } = useCreateTicket()

    const comboTutores =
        tutores ?
            tutores.map((tutor) => {
                return { value: tutor.codDocente, denominacion: tutor.codDocente + ' ' + tutor.nomDocente }
            }) : []

    const { showError, showSuccess } = useAlertMessage()
    const { handleSubmit, reset, control, formState: { errors } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(SemestreSchema),
        defaultValues: {
            codDocente: '',
            tema: '',
            fechaTicket: new Date().toJSON().slice(0, 10),
            hora: '',
            comentario: '',
            codSesion: 0,
            tiempoSesion: '',
            tipoSesion: '',
            ubicacionSesion: '',
            estado: 0,
            userCreacion: '',
            urlMaterial: '',
            codAlumnos: user?.codigo,
        }
    })
    const onSubmit = data => {
        const formData = {
            tema: data.tema,
            codDocente: data.codDocente,
            fechaTicket: data.fechaTicket.toJSON().slice(0, 10),
            hora: data.hora,
            comentario: data.comentario,
            codSesion: 0,
            tiempoSesion: '',
            tipoSesion: '',
            ubicacionSesion: '',
            estado: 0,
            userCreacion: '',
            urlMaterial: '',
            codAlumnos: user?.codigo,
        }
        const msg = 'No se pudo crear Semestre'
        const msg2 = 'Semestre creado correctamente'
        console.log(formData)
        mutate(formData, {
            onError: () => {
                showError(msg)
                reset()
            },
            onSuccess: () => {
                showSuccess(msg2)
                reset()
            }
        })
    }
    return (
        <>
            <ContentMainBox>
                <ContentMainBox.Title>
                    <div className='flex justify-between'>
                        Solicitar ticket
                    </div>
                </ContentMainBox.Title>
                <ContentMainBox.Content>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="wrap"
                        >
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        {comboTutores.length === 1
                                            ? <Input
                                                control={control}
                                                id='codDocente'
                                                valorChancado={comboTutores[0].value}
                                                label='Tutor'
                                                disabled
                                            />
                                            : <Input
                                                control={control}
                                                id='codDocente'
                                                label='Tutor'
                                                type='select'
                                                data={comboTutores}
                                            />
                                        }

                                        <Typography
                                            variant='subtitle2'
                                            align='left'
                                            color='red'
                                        >
                                            {errors.codDocente?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Input
                                            control={control}
                                            id='tema'
                                            label='Tema de reunión'
                                            placeholder='Ingrese tema de reunión'
                                        />
                                        <Typography
                                            variant='subtitle2'
                                            align='left'
                                            color='red'
                                        >
                                            {errors.tema?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            control={control}
                                            id='fechaTicket'
                                            label='Fecha'
                                            type='date'
                                        />
                                        <Typography
                                            variant='subtitle2'
                                            align='left'
                                            color='red'
                                        >
                                            {errors.fechaTicket?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            control={control}
                                            id='hora'
                                            label='Hora de inicio'
                                            type='time'
                                        />
                                        <Typography
                                            variant='subtitle2'
                                            align='left'
                                            color='red'
                                        >
                                            {errors.hora?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Input
                                            control={control}
                                            id='comentario'
                                            label='Comentario'
                                            placeholder='Deja un comentario'
                                            multiline
                                            rows={3}
                                        />
                                        <Typography
                                            variant='subtitle2'
                                            align='left'
                                            color='red'
                                        >
                                            {errors.comentario?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Button type='submit' fullWidth variant="contained" color='primary' >
                                            Guardar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                        </Grid>
                    </form>
                </ContentMainBox.Content>
            </ContentMainBox>
        </>
    )
}
