import React, { useState } from 'react'
import { useAuthContext } from '../../../context/userContext'
import { useGetTutoresDeAlumno } from '../../../hooks/useTutorAlumno'
import { SolicitarTutor } from './components/SolicitarTutor'
import { Ticket } from './components/Ticket'

export const SacarTicket = () => {
    const { user } = useAuthContext()
    const { data: tutores } = useGetTutoresDeAlumno(user?.codigo)
    const [tutor, setTutor] = useState([])

    return (
        <>
            {
                tutores?.length <= 2
                    ? <SolicitarTutor
                        setTutores={setTutor}
                    />
                    : <Ticket tutores={tutores} />
            }
        </>
    )
}
