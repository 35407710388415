import HomeIcon from '@mui/icons-material/Home'
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded'
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded'

export function alumnoNavConfig() {
  return {
    links: [
      { path: '.', name: 'Inicio', icon: HomeIcon },
      {
        path: 'sacartickets',
        name: 'Sacar Tickets',
        icon: ConfirmationNumberRoundedIcon
      },
      {
        path: 'mistickets',
        name: 'Mis Tickets',
        icon: ListAltRoundedIcon
      }
    ]
  }
}
