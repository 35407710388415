import { Grid, IconButton } from '@mui/material'
import React from 'react'
import ContentMainBox from '../../../components/MainBox'
import { DataGridStyle } from '../../../components/DataGridStyle'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { useGetAllTicket } from '../../../hooks/useTicket'
import { useAuthContext } from '../../../context/userContext'

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    field: 'codDocente',
    headerName: 'Código Tutor',
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'nomDocente',
    headerName: 'Docente',
    width: 300,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'tema',
    headerName: 'Tema',
    width: 300,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'fecTicket',
    headerName: 'Fecha',
    type: 'date',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'hora',
    headerName: 'Hora',
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'acciones',
    type: 'actions',
    headerName: 'Acciones',
    cellClassName: 'acciones',
    getActions: ({ codTikect }) => {
      const data = rows.find(row => row.codTikect === codTikect)
      return [
        <IconButton
          aria-label="acciones"
          onClick={() => { }}
        >
          {data.estado === 'APROBADO' ? <CheckCircleOutlineRoundedIcon color='primary' /> : <HighlightOffRoundedIcon color='secondary' />}
        </IconButton>,
      ]
    }
  }
]
const rows = [
  {
    id: 1,
    cod_tutor: '161756',
    tema: 'falta a examen',
    fecha: '12/12/22',
    hora: '17:00',
    estado: true
  },
  {
    id: 2,
    cod_tutor: '161756',
    tema: 'falta a examen',
    fecha: '12/12/22',
    hora: '17:00',
    estado: false
  },
  {
    id: 3,
    cod_tutor: '161756',
    tema: 'falta a examen',
    fecha: '12/12/22',
    hora: '17:00',
    estado: true
  },
  {
    id: 4,
    cod_tutor: '161756',
    tema: 'falta a examen',
    fecha: '12/12/22',
    hora: '17:00',
    estado: false
  },
  {
    id: 5,
    cod_tutor: '161756',
    tema: 'falta a examen',
    fecha: '12/12/22',
    hora: '17:00',
    estado: true
  },
  {
    id: 6,
    cod_tutor: '161756',
    tema: 'falta a examen',
    fecha: '12/12/22',
    hora: '17:00',
    estado: false
  },
  {
    id: 7,
    cod_tutor: '161756',
    tema: 'falta a examen',
    fecha: '12/12/22',
    hora: '17:00',
    estado: true
  },
]
export const MisTickets = () => {
  const { user } = useAuthContext()
  const { data: tickets } = useGetAllTicket(user?.codigo)
  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Mis tickets
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item className='mt-4' xs={12}>
              <DataGridStyle
                height={380}
                rows={tickets || []}
                columns={columns}
                getRowId={(row) => row.codTikect}
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}
