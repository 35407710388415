import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  GetAll,
  Create,
} from '../services/ticket'

const key = 'ticket'

export const useGetAllTicket = (codAlumno, enabled = true) =>
  useQuery([key, codAlumno], GetAll, {
    enabled
  })

export const useCreateTicket = () => {
  const queryClient = useQueryClient()
  return useMutation(Create, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}
