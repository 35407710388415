import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AsignarAlumnosTutor, GetTutoresDeAlumno } from '../services/tutorAlumno'

const key = 'tutorAlumno'


export const useAsignarAlumnosTutor = () => {
  const queryClient = useQueryClient()

  return useMutation(body => AsignarAlumnosTutor(body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}


export const useGetTutoresDeAlumno = (codAlumno, enabled = true) =>
  useQuery([key, codAlumno], GetTutoresDeAlumno, {
    enabled
  })
