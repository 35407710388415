import axios from 'axios'
import { useUsuario } from '../hooks/useUsuario'

export const GetAll = async ({queryKey}) => {
  const { full_path: fullPath } = useUsuario()
  const [_key, codAlumno] = queryKey
  const { data } = await axios.get(`${fullPath}/Ticket/${codAlumno}`)

  return data.tickets
}

export const Create = async body => {
  const { full_path: fullPath } = useUsuario()
  const { data } = await axios.post(`${fullPath}/Ticket/ticket-alumnos`, body)

  return data.data
}
