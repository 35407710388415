import { Route, Routes } from 'react-router-dom'
import Dashboard from '../../components/layout/dashboard'
import { ProtectedRoute } from '../../components/ProtectedRoute'
import Inicio from './inicio'
import { alumnoNavConfig } from './layoutAlumno'
import { MisTickets } from './MisTickets'
import { SacarTicket } from './SacarTicket'

const AlumnoDashboard = () => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard navConfig={alumnoNavConfig} />}>
        <Route element={<ProtectedRoute />}>
          <Route index element={<Inicio />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='sacartickets' element={<SacarTicket />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='mistickets' element={<MisTickets />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default AlumnoDashboard
