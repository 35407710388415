import axios from 'axios'
import { useUsuario } from '../hooks/useUsuario'

export const GetTutorDisponibleByAlumno = async ({queryKey}) => {
  const { full_path: fullPath } = useUsuario()
  const [_key, idAlumno] = queryKey
  const { data } = await axios.get(`${fullPath}/Tutor/Disponible/${idAlumno}`)

  return data.tutor
}

