import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Input from '../../../../../components/Input'
import ContentMainBox from '../../../../../components/MainBox'
import { useAlertMessage } from '../../../../../context/notifications'
import { useAuthContext } from '../../../../../context/userContext'
import { useGetTutorDisponibleByAlumno } from '../../../../../hooks/useTutor'
import { useAsignarAlumnosTutor } from '../../../../../hooks/useTutorAlumno'

const SemestreSchema = yup.object({
    codTutor: yup
        .string()
        .required('Campo requerido'),
    observacion: yup
        .string()
        .required('Campo requerido')
})

export const SolicitarTutor = ({ setTutores }) => {
    const { user } = useAuthContext()
    const { data: tutores } = useGetTutorDisponibleByAlumno(user?.codigo) || []
    const { mutate, isLoading, refetch } = useAsignarAlumnosTutor()
    const { showError, showSuccess } = useAlertMessage()

    const { handleSubmit, control, formState: { errors } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(SemestreSchema),
        defaultValues: {
            codTutor: '',
            observacion: '',
            codAlumnos: [codAlumno]
        }
    })
    const onSubmit = data => {
        const formData = {
            codTutor: data.codTutor,
            observacion: data.observacion,
            codAlumnos: [codAlumno]
        }
        mutate(formData, {
            onError: () => {
                showError('no se pudo asginar el tutor seleccionado')
            },
            onSuccess: () => {
                refetch
                showSuccess('se asignó el tutor seleccionado')
            }
        })
        setTutores(1)
    }

    const comboTutores =
        tutores ?
            tutores.map((tutor) => {
                return { value: tutor.idTutor, denominacion: tutor.idTutor + ' ' + tutor.nomTutor }
            }) : []
    return (
        <>
            <ContentMainBox>
                <ContentMainBox.Title>
                    <div className='flex justify-between'>
                        Solicitar tutor
                    </div>
                </ContentMainBox.Title>
                <ContentMainBox.Content>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="wrap"
                        >
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <Input
                                            control={control}
                                            id='codTutor'
                                            label='Tutor'
                                            type='select'
                                            data={comboTutores}
                                        />
                                    </Grid>
                                    <Typography
                                        variant='subtitle2'
                                        align='left'
                                        color='red'
                                        className='ml-5'
                                    >
                                        {errors.codTutor?.message}
                                    </Typography>
                                    <Grid item xs={12} >
                                        <Input
                                            control={control}
                                            id='observacion'
                                            label='Observación'
                                            placeholder='Deja un comentario'
                                            multiline
                                            rows={3}
                                        />
                                    </Grid>
                                    <Typography
                                        variant='subtitle2'
                                        align='center'
                                        color='red'
                                        className='ml-5'
                                    >
                                        {errors.observacion?.message}
                                    </Typography>
                                    <Grid item xs={12} >
                                        <Button type='submit' fullWidth variant="contained" color='primary'>
                                            Elegir
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                        </Grid>
                    </form>
                </ContentMainBox.Content>
            </ContentMainBox>
        </>
    )
}
